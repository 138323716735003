import { StepProps } from "antd";
import { getFileURLfromFilename } from "api/assets";
import clsx from "clsx";
import OrgApprovedModal from "components/OrgApprovedModal";
import useAppConfig from "hooks/useAppConfig";
import { createContext, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useBoolean } from "usehooks-ts";
import clevertap from "utils/clevertap";
import { Mixpanel } from "utils/mixpanel";
import sentry from "utils/sentry";
import { getCurrentUser, uploadAgencyLogoDoc } from "../Api";

declare const window: Window &
	typeof globalThis & {
		clarity: any;
	};

const defaultState: {
	user: ExtendedAuthUser;
	updateUser: React.Dispatch<React.SetStateAction<ExtendedAuthUser>>;
	isLoading: boolean;
	getSetUserAndNavigate: () => void;
	uploadDocRelatedWithOrg: (
		doc_type: "agency_logo" | "gst_certificate" | "business_registration_certificate",
		file: File
	) => Promise<string | undefined>;
	demoMode: boolean;
	setDemoMode: (value: boolean) => void;
	businessDetailsStatus: StepProps["status"];
	personalDetailsStatus: StepProps["status"];
	appConfig: TAppConfig;
	getAppConfig: (version: string) => Promise<TAppConfig | undefined>;
	patchAppConfig: (data: Record<string, any>) => Promise<TAppConfig | undefined>;
	showShadow: boolean;
	setShowShadow: React.Dispatch<React.SetStateAction<boolean>>;
} = {
	user: null,
	updateUser: () => {},
	isLoading: false,
	getSetUserAndNavigate: () => {},
	uploadDocRelatedWithOrg: () => new Promise(() => {}),
	demoMode: false,
	setDemoMode: () => {},
	businessDetailsStatus: "wait",
	personalDetailsStatus: "wait",
	appConfig: {
		metadata: {},
		updated_at: "",
		version_key: "initial_version_key[useAuthContext.tsx]"
	},
	getAppConfig: (v) => Promise.resolve(undefined),
	patchAppConfig: (d) => Promise.resolve(undefined),
	showShadow: false,
	setShowShadow: () => {}
};

export const AuthContext = createContext(defaultState);

export const AuthWrapper = ({ children }: { children: React.ReactNode }) => {
	const [user, setUser] = useState<ExtendedAuthUser>(null);
	const [isLoading, setIsLoading] = useState(true);
	const [demoMode, _setDemoMode] = useState(true);
	const { appConfig, getAppConfig, patchAppConfig } = useAppConfig();
	const [showShadow, setShowShadow] = useState(false);
	const { value: openApprovedModal, setTrue: onOpenApprovedModal, setFalse } = useBoolean(false);
	const isApproved = user?.organisation_id?.status === "APPROVED";

	const onCloseApprovedModal = () => {
		setFalse();
		patchAppConfig({ isOrgApproved: true });
	};
	const setDemoMode = useCallback(
		(value: boolean) => {
			const isApproved = user?.organisation_id.status === "APPROVED";
			if (!isApproved) {
				_setDemoMode(true);
				return;
			}
			_setDemoMode(value);
		},
		[user]
	);

	const businessDetailsStatus = useMemo(() => {
		if (
			!user?.organisation_id.state ||
			!user?.organisation_id.type ||
			!user?.organisation_id.document_proof?.s3_path ||
			!user?.organisation_id.document_proof?.type ||
			!user?.organisation_id.term_and_condition_policy.accepted_by ||
			!user?.organisation_id.absconding_term_and_condition_policy.accepted_by
		)
			return "wait";

		if (user.organisation_id.status === "REJECTED") return "error";
		return "finish";
	}, [user]);

	const personalDetailsStatus = useMemo(() => {
		if (!user?.organisation_id.point_of_contact.email || !user?.organisation_id.is_email_verified) return "wait";
		return "finish";
	}, [user]);

	const getLogoUrl = async (path?: string) => {
		if (!path) return;
		try {
			const signedURLs = await getFileURLfromFilename(path);

			return signedURLs[0].url;
		} catch (err) {
			return undefined;
		}
	};

	const setUserForMetrics = (user: ExtendedAuthUser) => {
		if (!user) return;

		// Mixpanel & Webengage
		Mixpanel.identify(user._id);
		Mixpanel.webengage.set("we_email", user.email);
		Mixpanel.webengage.set("we_phone", user.phone);
		Mixpanel.webengage.set("we_first_name", user.first_name);
		Mixpanel.webengage.set("we_last_name", user.last_name);
		Mixpanel.webengage.set("we_company", user.organisation_id.name);
		Mixpanel.webengage.set("KYC status", user.organisation_id.status);
		Mixpanel.people.set({ "KYC status": user.organisation_id.status });
		Mixpanel.people.set_once({
			$name: user.first_name + " " + user.last_name,
			"Agency Name": user.organisation_id.name,
			Phone: user.phone,
			$email: user.email
		});

		// Sentry
		sentry.setUser({ ...user, username: user.first_name + " " + user.last_name });

		// Clarity
		window.clarity?.("identify", user._id);
		window.clarity?.("set", "user", user.first_name + " " + user.last_name);
		window.clarity?.("set", "email", user.email);
		window.clarity?.("set", "phone", user.phone);

		// clevertap
		clevertap.onUserLogin(user);
	};

	const getSetUserAndNavigate = () => {
		(async () => {
			try {
				setIsLoading(true);
				const res = await getCurrentUser();
				const promises = [
					getAppConfig(res.data.frontend_app_version_key),
					getLogoUrl(res.data.organisation_id.agency_logo)
				] as const;
				if (res.success) {
					const [, logoUrl] = await Promise.all(promises);
					setUserForMetrics(res.data);
					setUser({ ...res.data, logoUrl });
				}
			} finally {
				setIsLoading(false);
			}
		})();
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useLayoutEffect(getSetUserAndNavigate, []);

	useEffect(() => {
		setDemoMode(false);
	}, [setDemoMode]);

	useEffect(() => {
		if (!appConfig) return;
		if (isApproved && !appConfig.metadata.isOrgApproved) {
			onOpenApprovedModal();
		}
	}, [appConfig, isApproved, onOpenApprovedModal, patchAppConfig]);

	const uploadDocRelatedWithOrg = async (
		doc_type: "agency_logo" | "gst_certificate" | "business_registration_certificate",
		file: File
	) => {
		if (!user) return;
		try {
			if (doc_type !== "agency_logo") return;
			const res = await uploadAgencyLogoDoc({ file, orgId: user.organisation_id._id });

			if (res.success) {
				const url = URL.createObjectURL(file);
				if (doc_type === "agency_logo") setUser({ ...user, logoUrl: url });
				return url;
			}
		} catch (error) {
			console.log("🚀 ~ Error in uploading doc", error);
			return "";
		}
	};

	return (
		<AuthContext.Provider
			value={{
				user,
				isLoading,
				getSetUserAndNavigate,
				updateUser: setUser,
				uploadDocRelatedWithOrg,
				demoMode,
				setDemoMode,
				businessDetailsStatus,
				personalDetailsStatus,
				appConfig,
				getAppConfig,
				patchAppConfig,
				showShadow,
				setShowShadow
			}}>
			<div
				className={clsx(
					"fixed left-0 top-0 z-50 flex h-screen w-screen items-center justify-center gap-2 bg-slate-100 transition-opacity duration-300",
					isLoading ? "visible opacity-100" : "pointer-events-none invisible opacity-0"
				)}>
				<span
					className="relative py-2 after:absolute after:-bottom-0.5 after:block after:h-0.5 after:w-full after:origin-center after:animate-[logo-loading_1s_ease-in-out_infinite] after:rounded-full 
				 after:bg-gray-300/60 after:transition-transform">
					<img
						width={210}
						height={32}
						src="/assets/images/logo-with-name.svg"
						alt="StampMyVisa"
						className="h-8 w-52"
					/>
				</span>
			</div>

			<div
				className={clsx(
					"transition-opacity duration-700 ease-5",
					isLoading ? "pointer-events-none invisible opacity-0" : "visible opacity-100"
				)}>
				{children}
			</div>
			<OrgApprovedModal open={openApprovedModal} onClose={onCloseApprovedModal} />
		</AuthContext.Provider>
	);
};

export const useAuthContext = () => {
	return useContext(AuthContext);
};

