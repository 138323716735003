import { X } from "@phosphor-icons/react";
import { ModalProps } from "antd";
import { ReactComponent as SmvBgSvg } from "assets/icons/SmvBg.svg";
import React from "react";
import colors from "tailwindcss/colors";
import { Button, clsx, Modal } from "x-wings";

export type MobileModalPropsType = ModalProps & {};

//TODO: footer callback possibility is not handled
const MobileModal: React.FC<MobileModalPropsType> = ({
	open,
	title,
	footer,
	children,
	className,
	styles,
	onCancel,
	...rest
}) => {
	return (
		<Modal
			open={open}
			onCancel={onCancel}
			closeIcon={false}
			styles={{
				...styles,
				content: {
					padding: "4px",
					paddingBottom: "20px",
					borderRadius: "20px",
					background: "white",
					...styles?.content
				},
				footer: { margin: "0", padding: "0", ...styles?.footer },
				header: {
					borderRadius: "16px 16px 4px 4px",
					padding: "16px",
					backgroundColor: colors.gray[200],
					margin: "0",
					overflow: "hidden",
					...styles?.header
				},
				body: { padding: "16px 12px", background: "unset", boxShadow: "unset", ...styles?.body }
			}}
			title={
				<div className="relative text-start">
					<SmvBgSvg className="absolute -bottom-4 -right-4" />
					{rest.closable === false && (
						<Button
							className="absolute right-0 top-0 z-[1000] !size-6 !min-w-6 !text-gray-700"
							shape="circle"
							icon={<X weight="bold" />}
							onClick={onCancel}></Button>
					)}
					{title}
				</div>
			}
			footer={
				<div className="mt-4">
					{React.isValidElement(footer) ? footer : null}
					<img
						width={210}
						height={16}
						src="/assets/images/logo-with-name.svg"
						alt="StampMyVisa"
						className="mx-auto mb-1 mt-5 h-4"
					/>
				</div>
			}
			className={clsx("absolute inset-x-0 bottom-0 top-[unset] !p-2", className)}
			{...rest}
			centered={undefined}>
			{children}
		</Modal>
	);
};

export default MobileModal;

