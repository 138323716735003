import NewTnCAgreementModal from "components/NewTnCAgreementModal";
import useIsMobile from "hooks/useIsMobile";
import { useEffect } from "react";
import { useAuthContext } from "screens/Auth/Context";
import { useBoolean, When } from "x-wings";
import DesktopLayout from "./DesktopLayout";
import MobileLayout from "./MobileLayout";

const MainLayout: React.FC = () => {
	const { appConfig, patchAppConfig } = useAuthContext();
	const isMobile = useIsMobile();
	const {
		value: openNewTnCAgreementModal,
		setTrue: onOpenNewTnCAgreementModal,
		setFalse: onCloseNewTnCAgreementModal
	} = useBoolean();

	useEffect(() => {
		if (!appConfig) return;
		if (appConfig.metadata.isNewTnCApproved) return;
		onOpenNewTnCAgreementModal();
	}, [appConfig, onOpenNewTnCAgreementModal]);

	return (
		<>
			<When isTrue={isMobile}>
				<MobileLayout />
			</When>
			<When isTrue={!isMobile}>
				<DesktopLayout />
			</When>
			<NewTnCAgreementModal
				open={openNewTnCAgreementModal}
				onAgree={() => {
					patchAppConfig({ isNewTnCApproved: true }).then(() => {
						onCloseNewTnCAgreementModal();
					});
				}}
			/>
		</>
	);
};

export default MainLayout;

